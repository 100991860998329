<template>
<div>
  <div v-if="showLoading" class="text-center" style="padding: 24px">
    <CSpinner/>
  </div>

  <CRow>
    <CCol>
      <ul>
        <li v-for="item in items" :key="item.seq">
          <CLink
            :href="item.url"
            target="_blank">
            {{ item.name }}
          </CLink>
        </li>
      </ul>
    </CCol>
  </CRow>

</div>
</template>

<script>
  import api from "../../api/api"

  export default {
    components: {
    },
    data() {
      return {
        items: [],
        showLoading: true,
        user: ''
      };
    },

    async created () {
      var idToken = localStorage.getItem('idToken')

      if(idToken===null || idToken===undefined || idToken==='undefined')
        this.$router.replace('/login', () => {})
      else{
        this.user = JSON.parse(localStorage.getItem('user'))
        this.listLink()
      }
    },

    methods: {
      async listLink () {
        this.items = []
        this.showLoading = true
        let apiRes = await api.listLink(this.user.id)
        this.showLoading = false

        if (apiRes.result === 'OK'){
          this.items = apiRes.links
          for(var i=0; i<this.items.length; i++){
            if(this.items[i].access==="LEADER") this.items[i].name = "[PEMBIMBING] " + this.items[i].name
            else if(this.items[i].access==="ADMIN") this.items[i].name = "[PEMBINA] " + this.items[i].name
          }
        }
        else {
          console.log("listLink ERR: " + apiRes.err)
          this.$router.replace('/login', () => {})
        }
      },
    }
  }
</script>
<style>
</style>
